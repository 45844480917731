import homeStyles from 'app/scss/layout/home.module.scss';
import layoutStyles from 'app/scss/layout/layout.module.scss';
import { useCallback, useState } from 'react';
import ArticleListCard from '~/components/Article/ArticleCard/ArticleListCard';
import SortButton from '~/components/Button/SortButton';
import ViewButton from '~/components/Button/ViewButton';
import HotTopics from '~/components/HotTopics/HotTopics';
import InfiniteLoader from '~/components/InfiniteLoader/InfiniteLoader';
import SignUpCard from '~/components/SignUpCard/SignUpCard';
import SignUpCardPaywall from '~/components/SignUpCardPaywall/SignUpCardPaywall';
import SubscribeCard from '~/components/SubscribeCard/SubscribeCard';
import { useUser } from '~/context/UserContext';
import { authTokenCookie } from '~/cookies.server';
import useChartbeat from '~/hooks/useChartbeat';
import useInfiniteFetcher from '~/hooks/useInfiniteFetcher';
import {
  Article,
  LoaderDataType,
  MetaTag,
  MinimalTagResource,
  PaginatedResponse,
} from '~/types';

import { LoaderFunctionArgs, json } from '@remix-run/node';
import { useLoaderData, useNavigate, useSearchParams } from '@remix-run/react';

const RESULTS_PER_PAGE = 10;

type TagsLoaderResponse = {
  data: MinimalTagResource[];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const params = new URL(request.url).searchParams;

  const page = params.get('page') ?? 1;

  const period = params.get('sort') ?? 'period_today';

  const cookieHeader = request.headers.get('Cookie');
  const token = await authTokenCookie.parse(cookieHeader);

  const headers: HeadersInit = {};

  if (token && token.token) {
    headers['Authorization'] = `Bearer ${token.token}`;
  }

  const response = await fetch(
    `${process.env.API_URL}/articles?per_page=${RESULTS_PER_PAGE}&page=${page}&sort=${period}&include=highlights,media,comments,votes,tags,references`,
    {
      headers: headers,
    },
  );

  const paginatedData: PaginatedResponse<Article> = await response.json();

  const tagsResponse: Response = await fetch(
    `${process.env.API_URL}/tags/hot?filter[period]=${period}`,
    {
      headers,
    },
  );

  const tags: TagsLoaderResponse = await tagsResponse.json();

  return json({
    paginatedData,
    tags: tags.data,
    originUrl: process.env.APP_HOST,
    facebookAppId: process.env.FACEBOOK_APP_ID,
    chartbeatUser: process.env.CHARTBEAT_USER ?? '',
    chartbeatDomain: process.env.CHARTBEAT_DOMAIN ?? '',
  });
}

type MetaArgs = {
  data: {
    originUrl: string;
    facebookAppId: string;
  };
};

export const meta: (args: MetaArgs) => MetaTag[] = ({ data }: MetaArgs) => {
  return [
    {
      title: 'BP News: Your Daily Dose of Trending News Highlights',
    },
    {
      name: 'description',
      content:
        'Stay up-to-date with BP News, summarizing top trending news into concise and engaging highlights. Discover the latest stories and insights every day.',
    },
    {
      property: 'og:title',
      content: 'BP News: Your Daily Dose of Trending News Highlights',
    },
    {
      property: 'og:description',
      content:
        'Stay up-to-date with BP News, summarizing top trending news into concise and engaging highlights. Discover the latest stories and insights every day.',
    },
    { property: 'og:url', content: data.originUrl },
    { property: 'og:type', content: 'website' },
    { property: 'fb:app_id', content: data.facebookAppId },
    { tagName: 'link', rel: 'canonical', href: data.originUrl },
  ];
};

export default function Index() {
  const [searchParams] = useSearchParams();

  const { data: articles, hasNextPage } =
    useInfiniteFetcher<LoaderDataType<typeof loader>>();

  const { tags } = useLoaderData<typeof loader>();

  const { user } = useUser();

  const currentSortOrder = searchParams.get('sort') || 'period_today';
  const [sortOrder, setSortOrder] = useState(currentSortOrder);
  const navigate = useNavigate();

  const handleSort = useCallback(
    (newSort: string) => {
      setSortOrder(newSort);

      const currentParams = new URLSearchParams(searchParams);
      currentParams.set('sort', newSort);

      navigate(`?${currentParams.toString()}`);
    },
    [searchParams, navigate],
  );

  useChartbeat({ section: '_home', title: 'Home' });

  return (
    <div className={layoutStyles.main}>
      <div className={layoutStyles.content}>
        <div className={layoutStyles.contentLeft}>
          {/* <WelcomeMessage /> */}
          {!!tags.length && <HotTopics tags={tags} />}
          <div className={layoutStyles.headingRow}>
            <h1 className={layoutStyles.heading}>Trending Now</h1>
            <div className={layoutStyles.sortAndViewButtons}>
              <ViewButton />
              <SortButton setSortOrder={handleSort} sortOrder={sortOrder} />
            </div>
          </div>
          <div className={homeStyles.articles}>
            {articles.map((article) => (
              <ArticleListCard key={article.id} article={article} />
            ))}
          </div>
          {!user ? <SignUpCardPaywall /> : hasNextPage && <InfiniteLoader />}
        </div>
        <div className={layoutStyles.contentRight}>
          {user ? <SubscribeCard /> : <SignUpCard />}
        </div>
      </div>
    </div>
  );
}
